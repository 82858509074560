// Libraries
import { Link } from 'gatsby-plugin-react-i18next'
import styled from 'styled-components'

// Custom
import { PopAboutUs } from 'utils/animations'

type ImgProps = {
  border: string
  top?: string
  right?: string
}

export const IntroSection = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blueDarkerDefault};
  padding: 59px 0px 103px 0px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 239px 0px 317px 0px;
  }
`

export const TitlesContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    align-items: flex-start;
  }
`

export const Title = styled.h1`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.white00};
  line-height: 33.6px;
  margin-bottom: 24px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h2};
    line-height: 57.6px;
    margin-bottom: 6px;
  }
`

export const Text = styled.p`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.white00};
  line-height: 27px;
  text-align: center;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 443px;
    text-align: left;
    font-size: ${({ theme }) => theme.fontSize.p};
  }
`

export const Image = styled.img<ImgProps>`
  position: absolute;
  width: 86px;
  top: calc(${({ top }) => top || 0}px + 72px);
  right: ${({ right }) => right || 0}px;
  border-radius: ${({ border }) => border || 0};
  opacity: 0.15;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 280px;
    opacity: 1;
    top: calc(${({ top }) => top || 0}px + 78px);
  }
`

export const ImagesContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    :nth-child(2) {
      ${Image} {
        opacity: 0;
        animation: ${PopAboutUs} 0.5s ease-in-out 0.3s forwards;
      }
    }
    :nth-child(3) {
      ${Image} {
        opacity: 0;
        animation: ${PopAboutUs} 0.5s ease-in-out 0.5s forwards;
      }
    }
    :nth-child(4) {
      ${Image} {
        opacity: 0;
        animation: ${PopAboutUs} 0.5s ease-in-out 0.8s forwards;
      }
    }
    :nth-child(5) {
      ${Image} {
        opacity: 0;
        animation: ${PopAboutUs} 0.5s ease-in-out 1.1s forwards;
      }
    }
  }
`

export const ContentSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ContentTopContainer = styled.div`
  padding: 64px 0px;
  text-align: center;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 152px 350px;
  }
`

export const ContentTopText = styled.p`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.gray01};
`

export const ContentCards = styled.div`
  width: 100%;
  padding: 26px 0px 91px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blue800};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    flex-direction: row;
    padding: 61px 0px 135px;
  }
`

export const ContentCardsTitle = styled.h1`
  text-align: center;
  margin: 0px 0px 93px 0px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.extraBold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.blueDarkerDefault};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    text-align: left;
    margin: 0px 0px 121px 0px;
    font-size: ${({ theme }) => theme.fontSize.h2};
  }
`

export const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    flex-direction: row;
  }
`

export const Cards = styled.div`
  width: 310px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 53px;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 280px;
    align-items: flex-start;
  }
`

export const CardTop = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 22px;
`

export const CardTopTitle = styled.h2`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.blueDarkerLight};
`

export const IconBackground = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: ${({ theme }) => theme.colors.white00};
  margin-right: 17px;
`

export const Icon = styled.img`
  object-fit: fill;
`

export const Description = styled.p`
  margin: 0px;
  text-align: center;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.p};
  color: ${({ theme }) => theme.colors.blueDarkerLighter};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    width: 280px;
    text-align: left;
  }
`

export const ContentBottomContainer = styled.div`
  padding: 127px 0px;
  text-align: center;
  width: 100%;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    padding: 145px 240px;
  }
`

export const ContentBottomTitle = styled.h1`
  margin: 0px 0px 16px 0px;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.h5};
  color: ${({ theme }) => theme.colors.blueDarkerLight};
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h2};
  }
`

export const ContentBottomText = styled.p`
  margin: 0px;
  font-family: ${({ theme }) => theme.fontFamily.inter};
  font-weight: ${({ theme }) => theme.fontWeight.regular};
  font-size: ${({ theme }) => theme.fontSize.xsmall};
  color: ${({ theme }) => theme.colors.gray01};
`

export const ContactUs = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.blue800};
`

export const ContactUsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 43px 0px;
  ${Text} {
    width: auto;
    margin: 0px;
    font-family: ${({ theme }) => theme.fontFamily.lato};
    font-size: ${({ theme }) => theme.fontSize.p};
    color: ${({ theme }) => theme.colors.blueDarkerLighter};
    line-height: 27px;
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    flex-direction: row;
    padding: 69px 0px;
    ${Text} {
      font-size: ${({ theme }) => theme.fontSize.h4};
    }
  }
`

export const ContactButton = styled(Link)`
  position: relative;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  font-size: ${({ theme }) => theme.fontSize.p};
  color: ${({ theme }) => theme.colors.blueDarkerLighter};
  text-decoration: none;
  ::after {
    content: '';
    position: absolute;
    margin: 7px 0px 0px 5px;
    width: 0px;
    height: 0px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid ${({ theme }) => theme.colors.blueDarkerLighter};
  }
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktopMin}px) {
    font-size: ${({ theme }) => theme.fontSize.h4};
    margin-left: 15px;
    ::after {
      margin: 14px 0px 0px 10px;
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      border-left: 8px solid ${({ theme }) => theme.colors.blueDarkerLighter};
    }
  }
`
