// React
import { FC } from 'react'

// Libraries
import { graphql } from 'gatsby'
import { useTranslation } from 'react-i18next'

// Custom
import {
  CardContainer,
  Cards,
  CardTop,
  CardTopTitle,
  ContactButton,
  ContactUs,
  ContactUsContainer,
  ContentBottomContainer,
  ContentBottomText,
  ContentBottomTitle,
  ContentCards,
  ContentCardsTitle,
  ContentSection,
  ContentTopContainer,
  ContentTopText,
  Description,
  Icon,
  IconBackground,
  Image,
  ImagesContainer,
  IntroSection,
  Text,
  Title,
  TitlesContainer,
} from './styled'
import { PaddingContainer, SimpleContainer } from 'components/UI/Containers'
import { ABOUT_US, SEO } from 'locales'
import { theme } from 'lib/styled-components/theme'
import SeoElement from 'components/SeoElement'
import WrapProvider from 'WrapProvider'
import useMediaQuery from 'hooks/useMediaQuery'

// Assets
import Img01 from 'images/images/aboutUsImg01.png'
import Img02 from 'images/images/aboutUsImg02.png'
import Img03 from 'images/images/aboutUsImg03.png'
import Img04 from 'images/images/aboutUsImg04.png'
import LightBulb from 'images/icons/lightBulb.svg'
import PacMan from 'images/icons/pacMan.svg'
import People from 'images/icons/people.svg'
import StackPaper from 'images/icons/stackPaper.svg'

const IMAGES = [
  {
    label: Img01,
    border: { desk: '0px 10px 0px 100px', mobile: '0px 5px 0px 50px' },
    top: { desk: '66', mobile: '48' },
    right: { desk: '365', mobile: '110' },
  },
  {
    label: Img02,
    border: { desk: '0px 100px 0px 10px', mobile: '0px 50px 0px 5px' },
    top: { desk: '157', mobile: '70' },
    right: { desk: '52', mobile: '13' },
  },
  {
    label: Img03,
    border: { desk: '0px 100px 0px 10px', mobile: '0px 50px 0px 5px' },
    top: { desk: '353', mobile: '137' },
    right: { desk: '504', mobile: '153' },
  },
  {
    label: Img04,
    border: { desk: '0px 10px 0px 100px', mobile: '0px 5px 0px 50px' },
    top: { desk: '455', mobile: '168' },
    right: { desk: '175', mobile: '51' },
  },
]

const CARDS = [
  {
    icon: PacMan,
    title: ABOUT_US.dna.cards.geeks.title,
    description: ABOUT_US.dna.cards.geeks.text,
  },
  {
    icon: StackPaper,
    title: ABOUT_US.dna.cards.cocreation.title,
    description: ABOUT_US.dna.cards.cocreation.text,
  },
  {
    icon: LightBulb,
    title: ABOUT_US.dna.cards.innovation.title,
    description: ABOUT_US.dna.cards.innovation.text,
  },
  {
    icon: People,
    title: ABOUT_US.dna.cards.inclusive.title,
    description: ABOUT_US.dna.cards.inclusive.text,
  },
]

const AboutUs: FC = () => {
  const { t } = useTranslation()
  const isDesktop = useMediaQuery(
    `(min-width: ${theme.breakpoints.desktopMin}px)`,
  )

  const renderImages = () => {
    const images = IMAGES?.map((items, key) => {
      return (
        <ImagesContainer key={key}>
          {isDesktop ? (
            <Image
              src={items.label}
              border={items.border.desk}
              right={items.right.desk}
              top={items.top.desk}
            />
          ) : (
            <Image
              src={items.label}
              border={items.border.mobile}
              right={items.right.mobile}
              top={items.top.mobile}
            />
          )}
        </ImagesContainer>
      )
    })
    return images
  }

  const renderCards = () => {
    const cards = CARDS?.map((items, key) => {
      return (
        <Cards key={key}>
          <CardTop>
            <IconBackground>
              <Icon src={items.icon} />
            </IconBackground>
            <CardTopTitle>{t(items.title)}</CardTopTitle>
          </CardTop>
          <Description>{t(items.description)}</Description>
        </Cards>
      )
    })
    return cards
  }

  return (
    <WrapProvider>
      <SeoElement
        title={SEO.aboutUs.title}
        description={SEO.aboutUs.description}
      />
      <SimpleContainer>
        <IntroSection>
          <PaddingContainer>
            <TitlesContainer>
              <Title>{t(ABOUT_US.title)}</Title>
              <Text>{t(ABOUT_US.subtitle)}</Text>
            </TitlesContainer>
            {renderImages()}
          </PaddingContainer>
        </IntroSection>
        <ContentSection>
          <PaddingContainer>
            <ContentTopContainer>
              <ContentTopText>{t(ABOUT_US.text)}</ContentTopText>
            </ContentTopContainer>
          </PaddingContainer>
          <ContentCards>
            <PaddingContainer flex="column">
              <ContentCardsTitle>{t(ABOUT_US.dna.title)}</ContentCardsTitle>
              <CardContainer>{renderCards()}</CardContainer>
            </PaddingContainer>
          </ContentCards>
          <PaddingContainer>
            <ContentBottomContainer>
              <ContentBottomTitle>
                {t(ABOUT_US.bottomSection.main)}
              </ContentBottomTitle>
              <ContentBottomText>
                {t(ABOUT_US.bottomSection.secondary)}
              </ContentBottomText>
            </ContentBottomContainer>
          </PaddingContainer>
        </ContentSection>
        <ContactUs>
          <PaddingContainer>
            <ContactUsContainer>
              <Text>{t(ABOUT_US.bottomSection.contact)}</Text>
              <ContactButton to="/contact">
                {t(ABOUT_US.bottomSection.ctaText)}
              </ContactButton>
            </ContactUsContainer>
          </PaddingContainer>
        </ContactUs>
      </SimpleContainer>
    </WrapProvider>
  )
}

export default AboutUs

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
